@media only screen and (min-width: 1000px) {
    .rdrCalendarWrapper {
        margin-left: 14px;
    }

    .rdrDay {
        border-radius: 0 !important;
    }

    .rdrDayNumber {
        border-radius: 0 !important;
    }

    .rdrWeekDay {
        background-color: #5076FF;
        color: white !important;
        font-weight: bold !important;
    }

    .rdrWeekDays {
        background-color: #5076FF;
        color: white !important;
        border-top-left-radius: 8px !important;
        border-top-right-radius: 8px !important;
        overflow: hidden;
        margin-top: -10px !important;
        padding-bottom: 6px !important;
    }

    .rdrMonthName {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 30px !important;
        font-weight: bold !important;
        color: #343a25 !important;
        font-size: 16px !important;
    }

    .rdrMonthAndYearPickers {
        display: none !important;
    }

    .rdrMonth {
        margin-top: -47px;
        margin-right: 20px;
    }

    .rdrNextPrevButton {
        background-color: white !important;
        border-radius: 20px !important;
        width: 30px !important;
        height: 30px !important;
        border: 1px solid #dcdcdc !important;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    .rdrNextButton {
        background: url('https://images.lagodigardacamping.com/bottone-freccia-dx-blu.svg') !important;
        background-size: cover !important;
        z-index: 15;
    }

    .rdrPprevButton {
        background: url('https://images.lagodigardacamping.com/bottone-freccia-sx-blu.svg') !important;
        background-size: cover !important;
        z-index: 15;
    }

    .rdrNextButton i {
        color: #5076FF !important;
        border-color: transparent transparent transparent #5076FF !important;
        display: none !important;
    }

    .rdrPprevButton i {
        color: #5076FF !important;
        border-color: transparent #5076FF transparent transparent !important;
        display: none !important;
    }

    .rdrMonthAndYearWrapper {
        padding-right: 20px;
    }
}